@charset "UTF-8";
@import url(//fonts.googleapis.com/earlyaccess/notosansjapanese.css);

$breakpoints: (
  'small': 'all and (max-width: 750px)',
);
$color-border: rgb(167, 167, 167);
$color-green: #77cc44;
$color-orange: #ea8228;
$color-tan: #feefd8;
$color-text: #000000;
$color-darkgrey: rgb(196, 196, 196);
$contents-width: 980px;
$duration: .5s;
$font-serif: 'NotoSerifCJKjp', serif;
$font-sans: 'NotoSansCJKjp', sans-serif;
$font-oswald: 'Oswald', 'NotoSansCJKjp', sans-serif;
$offset-basic: 5vw;
$contact-width: 250px;
$copyright-height: 50px;
$menu-size: 96px;

@mixin mq($breakpoint: small) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

%nice-button {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.4rem;
  font-weight: 400;
  justify-content: space-between;
  letter-spacing: .1em;
  line-height: 1.8;
  margin: 1em 0 0;
  text-align: justify;
  a {
    border: 2px solid lighten($color-border, 20%);
    color: $color-text;
    display: inline-block;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: .3em;
    line-height: 1;
    padding: .75em calc(22px + .75em) .75em .75em;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: $duration/2;
    width: 47.5%;
    &:hover, &:active {
      border-color: $color-orange;
      background: $color-orange;
      color: #fff;
    }
    &::after {
      background: url(../images/common/pict_arrow_next.png) no-repeat center center;
      background-size: contain;
      content: '';
      display: inline-block;
      height: 22px;
      position: absolute;
      right: .75em;
      top: 50%;
      transform: translate(0, -50%);
      vertical-align: middle;
      width: 22px;
    }
    &.local {
      margin: 0 1em 1em 0;
      max-width: 45%;
      min-width: 20em;
      &::after {
        background: url(../images/common/pict_arrow_local.png) no-repeat center center;
      }
      @include mq() {
        margin-right: 0;
        max-width: 100%;
        width: 100%;
      }
    }
  }
} // %nice-button

@font-face {
  font-family: 'NotoSerifCJKjp';
  font-style: normal;
  font-weight: 200;
  src:
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-ExtraLight.woff2) format('woff2'),
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-ExtraLight.woff) format('woff'),
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-ExtraLight.otf) format('opentype');
}
@font-face {
  font-family: 'NotoSerifCJKjp';
  font-style: normal;
  font-weight: 300;
  src:
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Light.woff2) format('woff2'),
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Light.woff) format('woff'),
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Light.otf) format('opentype');
}
@font-face {
  font-family: 'NotoSerifCJKjp';
  font-style: normal;
  font-weight: 400;
  src:
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Regular.woff2) format('woff2'),
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Regular.woff) format('woff'),
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Regular.otf) format('opentype');
}
@font-face {
  font-family: 'NotoSerifCJKjp';
  font-style: normal;
  font-weight: 500;
  src:
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Medium.woff2) format('woff2'),
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Medium.woff) format('woff'),
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Medium.otf) format('opentype');
}
@font-face {
  font-family: 'NotoSerifCJKjp';
  font-style: normal;
  font-weight: 600;
  src:
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-SemiBold.woff2) format('woff2'),
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-SemiBold.woff) format('woff'),
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-SemiBold.otf) format('opentype');
}
@font-face {
  font-family: 'NotoSerifCJKjp';
  font-style: normal;
  font-weight: 700;
  src:
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Bold.woff2) format('woff2'),
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Bold.woff) format('woff'),
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Bold.otf) format('opentype');
}
@font-face {
  font-family: 'NotoSerifCJKjp';
  font-style: normal;
  font-weight: 900;
  src:
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Black.woff2) format('woff2'),
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Black.woff) format('woff'),
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Black.otf) format('opentype');
}
@font-face {
  font-family: 'NotoSansCJKjp';
  font-style: normal;
  font-weight: 100;
  src:
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Thin.woff2) format('woff2'),
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Thin.woff) format('woff'),
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Thin.otf) format('opentype');
}
@font-face {
  font-family: 'NotoSansCJKjp';
  font-style: normal;
  font-weight: 300;
  src:
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Light.woff2) format('woff2'),
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Light.woff) format('woff'),
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Light.otf) format('opentype');
}
@font-face {
  font-family: 'NotoSansCJKjp';
  font-style: normal;
  font-weight: 350;
  src:
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-DemiLight.woff2) format('woff2'),
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-DemiLight.woff) format('woff'),
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-DemiLight.otf) format('opentype');
}
@font-face {
  font-family: 'NotoSansCJKjp';
  font-style: normal;
  font-weight: 400;
  src:
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Regular.woff2) format('woff2'),
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Regular.woff) format('woff'),
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Regular.otf) format('opentype');;
}
@font-face {
  font-family: 'NotoSansCJKjp';
  font-style: normal;
  font-weight: 500;
  src:
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Medium.woff2) format('woff2'),
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Medium.woff) format('woff'),
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Medium.otf) format('opentype');
}
@font-face {
  font-family: 'NotoSansCJKjp';
  font-style: normal;
  font-weight: 700;
  src:
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Bold.woff2) format('woff2'),
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Bold.woff) format('woff'),
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Bold.otf) format('opentype');
}
@font-face {
  font-family: 'NotoSansCJKjp';
  font-style: normal;
  font-weight: 900;
  src:
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Black.woff2) format('woff2'),
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Black.woff) format('woff'),
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Black.otf) format('opentype');
}
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  src:
    url(../fonts/Oswald/Oswald-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
  src:
    url(../fonts/Oswald/Oswald-SemiBold.ttf) format('truetype');
}

html {
  font-size: 62.5%; /* font-size 1em = 10px on default browser settings */
  height: 100%;
}
body {
  font-family: $font-sans;
  min-height: 100%;
  position: relative; /* go-to-top 対応 */
}
header {
  display: block;
  position: relative;
  .sitelogo-area {
    font-size: 2.4rem;
    font-weight: 500;
    letter-spacing: .2em;
    line-height: 1.5;
    @include mq() {
      // font-size: 3rem;
    //   padding-top: $menu-size;
    }
    a {
      color: $color-text;
      display: inline-block;
      padding: 1em 0 .5em;
      text-decoration: none;
    }
    span {
      display: block;
      font-size: 1.6rem;
      font-size: 65%;
      font-weight: 400;
    }
  }
}
footer {
  border-top: 1px solid $color-darkgrey;
  display: block;
  margin: 5rem 0 0;
  position: relative;
  .address-area {
    font-size: 1.5rem;
    font-weight: 350;
    padding: 2rem 0;
    address {
      display: block;
      letter-spacing: .2em;
      line-height: 1;
      margin: 0;
      padding: 0;
      strong {
        display: block;
        font-weight: 500;
        padding: 0 0 1em 0;
      }
    }
  }
  .copyright-area {
    align-items: center;
    background: $color-darkgrey;
    color: #fff;
    display: flex;
    font-size: 1.4rem;
    font-weight: 350;
    height: $copyright-height;
    letter-spacing: .1em;
  }
}
main {
  display: block;
}
h1 {
  font-family: $font-serif;
  font-size: 5rem;
  font-weight: 400;
  letter-spacing: .18em;
  &.top {
    font-size: 4rem;
    letter-spacing: .08em;
    padding: 1em 0 0;
    text-align: center;
    &::before {
      background-image: none;
      display: none;
    }
    strong {
      font-size: 120%;
    }
  }
  strong {
    color: $color-orange;
    font-weight: 400;
  }
  span {
    font-size: 80%;
  }
}
h2 {
  border-bottom: 3px solid #000;
  border-top: 3px transparent;
  display: inline-block;
  font-size: 2.4rem;
  font-weight: 500;
  letter-spacing: .18em;
  margin: 0;
  padding: .5em 0;
}
.contents-width {
  margin: 0 auto;
  width: $contents-width;
  @include mq() {
    width: 90vw;
  }
}
.h2-area {
  background: $color-tan;
  text-align: center;
}
.go-to-top {
  background: #000;
  bottom: 5vw;
  cursor: pointer;
  height: 120px;
  opacity: 0;
  position: fixed;
  right: 0;
  text-align: center;
  transition: $duration;
  width: 40px;
  z-index: 1;
  @include mq() {
    height: 90px;
    width: 90px;
  }
  a {
    display: block;
    height: 100%;
    position: relative;
    width: 100%;
  }
  &::before {
    background: url(../images/common/pict_go_to_top.png);
    background-size: contain;
    content: '';
    height: 24px;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, 24px);
    width: 24px;
    @include mq() {
      height: 50px;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 50px;
    }
  }
  &.active {
    opacity: 1;
  }
}
.contact-area {
  background: $color-orange;
  color: #fff;
  padding: 0 1.5rem 1.5rem;
  position: absolute;
  right: calc( (100vw - #{$contents-width}) / 2);//5vw;
  text-align: center;
  width: $contact-width;
  @include mq() {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    right: auto;
    width: 100%;
  }
  &.in-header {
    border-radius: 0 0 1rem 1rem;
    top: 0;
    @include mq() {
      border-radius: 0;
      padding: 1em 0;
      top: auto;
    }
  }
  &.in-footer {
    border-radius: 1rem 1rem 0 0;
    bottom: $copyright-height;
    @include mq() {
      border-radius: 0;
      bottom: auto;
      padding: 1em 0;
    }
  }
  p {
    letter-spacing: 1;
    line-height: 1;
    margin: 0;
    &:first-child {
      font-size: 1.6rem;
      font-weight: 700;
      letter-spacing: .1em;
      padding: 1.25em 0;
      @include mq() {
        font-size: 2.2rem;
        padding: 0;
      }
    }
    &:nth-child(2) {
      color: $color-text;
      font-family: $font-oswald;
      font-size: 3rem;
      font-weight: 600;
      padding: 0 0 .5em;
      @include mq() {
        font-size: 4rem;
        margin: 0 .5em;
        padding: 0;
      }
      &::before {
        background: url(../images/common/pict_tel.png) no-repeat center center;
        background-size: contain;
        content: '';
        display: inline-block;
        height: .8em;
        margin: 0 .25em 0 0;
        width: .8em;
      }
      a {
        color: $color-text;
        text-decoration: none;
      }
    }
    &:nth-child(3) {
      font-size: 1.4rem;
      font-weight: 350;
      letter-spacing: 0.14em;
      padding: 0 0 1.25em;
      @include mq() {
        font-size: 2rem;
        padding: 0 1em 0 0;
      }
    }
    &:last-child {
      a {
        background: #fff;
        border-radius: 2rem;
        color: $color-orange;
        display: block;
        font-size: 1.5rem;
        font-weight: 700;
        letter-spacing: .05em;
        padding: .5em 0;
        text-align: center;
        text-decoration: none;
        transition: $duration;
        @include mq() {
          display: inline-block;
          font-size: 2rem;
          margin: .5em 0;
          padding: .5em 1em;
          vertical-align: middle;
        }
        &::after {
          color: $color-text;
          content: '▶︎';
          margin: 0 0 0 .25em;
          transition: $duration;
        }
        &:hover {
          background: #000;
          color: #fff;
          &::after {
            color: #fff;
          }
        }
      }
    }
  }
} // .contact-area
.global-menu-area {
  background: #e9e9e9;
  #toggle-menu {
    display: none;
  }
  label {
    display: none;
    // @include mq() {
    //   background: $color-darkgrey;
    //   display: block;
    //   font-size: 2rem;
    //   height: $menu-size;
    //   padding: 1em 0;
    //   position: absolute;
    //   right: 0;
    //   text-align: center;
    //   top: 0;
    //   width: $menu-size;
    // }
  }
  nav {
    display: flex;
    padding: 0 calc(#{$contact-width} + #{$offset-basic}) 0 0;
    @include mq() {
      padding: 0;
    }
  }
  &.in-header {
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: .1em;
    @include mq() {
      font-size: 2rem;
    //   left: 0;
    //   position: absolute;
    //   top: 0;
    //   width: 100%;
    }
    nav {
      justify-content: space-between;
      a {
        border-bottom: 3px solid transparent;
        border-top: 3px solid transparent;
        color: $color-text;
        display: block;
        padding: 1em 0;
        text-decoration: none;
        transition: $duration;
        &.active {
          border-bottom: 3px solid $color-orange;
        }
        &:hover, &:active {
          border-bottom: 3px solid $color-orange;
        }
        // @include mq() {
        //   height: $menu-size;
        // }
      }
    }
  }
  &.in-footer {
    font-size: 1.4rem;
    font-weight: 350;
    letter-spacing: .1em;
    padding: 1em 0;
    @include mq() {
      padding: 0;
    }
    nav {
      flex-wrap: wrap;
      @include mq() {
        flex-direction: column;
        padding: 0;
      }
      a {
        color: $color-text;
        display: block;
        line-height: 1;
        padding: .5em 3em .5em 0;
        text-decoration: none;
        transition: $duration / 2;
        @include mq() {
          border-bottom: 1px dotted $color-border;
          font-size: 120%;
          padding: 1em 0;
          position: relative;
          width: 100%;
          &:last-child {
            border-bottom: none;
          }
        }
        &:hover, &:active {
          color: $color-orange;
        }
        &::after {
          background: url(../images/common/pict_arrow_next.png) no-repeat center center;
          background-size: contain;
          content: '';
          display: inline-block;
          height: .75em;
          margin: 0 0 0 .125em;
          width: .75em;
          @include mq() {
            position: absolute;
            right: 1em;
            top: 50%;
            transform: translate(0, -50%);
          }
        }
      }
    }
  }
} // .global-menu-area
.title-area {
  position: relative;
  &.top {
    padding-left: 0;
    text-align: center;
    &::before {
      background: rgb(140,118,108);
      bottom: 21px;
      content: '';
      display: block;
      height: 3px;
      left: 0vw;
      position: absolute;
      width: 100%;
      z-index: -1;
    }
  }
  &.knowledge {
    $size-image: 300px;
    $size-image-narrow: 250px;
    padding: 5rem 0 5rem $size-image;
    @include mq() {
      padding: 5rem 0 5rem $size-image-narrow;
    }
    &::before {
      background: url(../images/knowledge/illust_main.jpg) no-repeat top center;
      background-size: contain;
      content: '';
      display: inline-block;
      height: $size-image;
      left: 0;
      position: absolute;
      top: 4rem;
      width: $size-image;
      @include mq() {
        height: $size-image-narrow;
        width: $size-image-narrow;
      }
    }
    .buttons {
      @extend %nice-button;
      @include mq() {
        justify-content: flex-start;
      }
    }
  }
  &.other {
    padding: 7rem 0 5rem;
    text-align: center;
  }
  @include mq() {
    padding: 0 5vw;
  }
  h1 + p {
    font-size: 1.6rem;
    font-weight: 350;
    letter-spacing: .1em;
    line-height: 2;
    margin: 1em 0;
    @include mq() {
      padding: 0 5vw;
    }
  }
  figure {
    display: block;
    img {
      width: 100%;
    }
  }
} // &.title-area
.top-contents-area {
  &.about-area {
    $current-offset: 4rem;
    background: $color-tan;
    border-radius: 3rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5rem;
    margin-top: 5rem;
    padding: 0 $current-offset $current-offset;
    position: relative;
    @include mq() {
      padding: 0 $offset-basic $offset-basic;
    }
    .title {
      background: $color-orange;
      border-radius: 1em;
      color: #fff;
      flex-basis: 95%;
      font-size: 2.2rem;
      letter-spacing: .1em;
      line-height: 1;
      margin: -1em auto 2em;
      padding: .5em 2em;
      text-align: center;
    }
    dl {
      display: block;
      width: calc((100% - (#{$current-offset} * 2)) / 2);
      @include mq() {
        width: calc((100% - (#{$offset-basic} * 1)) / 2);;
      }
      dt {
        color: $color-orange;
        font-size: 2rem;
        font-weight: 500;
        letter-spacing: .15em;
        line-height: 1.2;
        margin: 0 0 1em;
        padding: 0 0 0 calc(37px + .5em);
        text-indent: calc(-1 * (37px + .5em));
        &::before {
          content: url(../images/top/pict_attention.png);
          margin: 0 .5em 0 0;
          vertical-align: middle;
        }
      }
      dd {
        font-size: 1.4rem;
        font-weight: 400;
        letter-spacing: .1em;
        line-height: 2;
        text-align: justify;
      }
    }
    figure {
      text-align: center;
      img {
        vertical-align: bottom;
      }
    }
    .conclution {
      color: $color-orange;
      font-size: 2.8rem;
      font-weight: 400;
      letter-spacing: .1em;
      text-align: center;
      width: 100%;
      &::before {
        border-left:  150px solid transparent;
        border-right: 150px solid transparent;
        border-top: 50px solid lighten($color-orange, 30%);
        content: '';
        display: block;
        margin: 0 auto .5em;
        width: 300px;
      }
    }
  } // &.about-area
  &.others-area {
    display: flex;
    @include mq() {
      flex-direction: column;
    }
    .navi-area {
      margin: 0 5% 0 0;
      width: 70%;
      @include mq() {
        width: 100%;
      }
      dl {
        margin: 0 0 6rem;
        padding: 0 0 0 130px;
        position: relative;
        &::before {
          display: inline-block;
          left: 0;
          position: absolute;
          top: 0;
          width: 130px;
        }
        &:first-child {
          &::before {
            content: url(../images/top/illust_service.jpg);
          }
        }
        &:nth-child(2) {
          &::before {
            content: url(../images/top/illust_knowledge.jpg);
          }
        }
        &:nth-child(3) {
          &::before {
            content: url(../images/top/illust_q_and_a.jpg);
          }
          dd {
            a {
              width: 100%;
            }
          }
        }
        dt {
          border-bottom: 1px solid $color-border;
          font-size: 2rem;
          font-weight: 500;
          letter-spacing: .1em;
          line-height: 1;
          padding: 0 0 .75em;
        }
        dd {
          @extend %nice-button;
        }
      }
    }
    .news-area {
      padding: 0 0 5rem;
      width: 25%;
      @include mq() {
        width: 100%;
      }
      .title {
        border-bottom: 1px solid $color-border;
        font-size: 2rem;
        font-weight: 500;
        letter-spacing: .1em;
        line-height: 1;
        padding: 0 0 .75em 3em;
        position: relative;
        &::before {
          content: url(../images/top/pict_news.png);
          display: inline-block;
          left: 0;
          margin: 0 .5em 0 0;
          position: absolute;
          top: 50%;
          transform: translate(0, -65%);
        }
      } // .title
      .news {
        list-style: none;
        padding: 0;
        li {
          border-bottom: 1px dotted $color-border;
          font-size: 1.4rem;
          font-weight: 350;
          letter-spacing: .1em;
          line-height: 1.5;
          padding: 1em 2em 1em 0;
          position: relative;
          text-align: justify;
          width: 100%;
          a {
            color: $color-text;
            display: block;
            text-decoration: none;
            transition: $duration / 2;
            &::after {
              background: url(../images/common/pict_arrow_next.png) no-repeat center center;
              background-size: contain;
              content: '';
              height: 16px;
              position: absolute;
              right: 0;
              top: 50%;
              transform: translate(0, -50%);
              width: 16px;
            }
            &:hover, &:active {
              color: $color-green;
            }
            span {
              color: $color-green;
              display: block;
              font-size: 1.2rem;
              line-height: 1;
              margin: 0 0 .5em;
            }
          }
        }
      } // .news
    } // .news-area
    .banners-area {
      padding: 2rem 0 0;
      text-align: center;
      img {
        width: 100%;
        @include mq() {
          width: 60vw;
        }
      }
    }
  }
} // .top-contents-area
.knowledge-contents-area {
  &.rules-area {
    @include mq() {
      flex-direction: column;
    }
    ol {
      counter-reset: rules;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      list-style: none;
      margin: 4rem 0;
      padding: 0;
      li {
        position: relative;
        width: calc((100% - 5rem) / 2);
        @include mq() {
          width: 100%;
        }
        &::before {
          background: $color-orange;
          border-radius: calc(50px / 2);
          color: #fff;
          counter-increment: rules;
          content: counter(rules);
          display: inline-block;
          font-family: $font-oswald;
          font-size: 2.2rem;
          font-weight: 700;
          line-height: 1;
          left: 0;
          margin: 0;
          padding: calc(57px - (2.2rem * 2)) 0;
          position: absolute;
          text-align: center;
          top: 0;
          width: 50px;
        }
        &::after {
          content: url(../images/knowledge/illust_rules.png);
          position: absolute;
          left: 35px;
          top: 10px;
        }
        .title {
          border-bottom: 1px solid $color-border;
          font-size: 2.4rem;
          font-weight: 400;
          letter-spacing: .18em;
          line-height: 1.2;
          margin: 0 0 1em;
          padding: 1.5em 0 .75em;
          text-indent: 100px;
        }
        div:nth-child(2) {
          display: flex;
          justify-content: space-between;
          p {
            font-size: 1.4rem;
            font-weight: 400;
            letter-spacing: .18em;
            line-height: 2;
            text-align: justify;
            width: 70%;
          }
          figure {
            width: 25%;
            img {
              width: 100%;
            }
          }
        }
      } // li
    } // ol
  } // &.rules-area
  &.flow-area {
    ol {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      list-style: none;
      padding: 5rem 0;
      li {
        border: 1px solid $color-border;
        margin: 0 40px 40px 0;
        // margin: 0 4vw 40px 0;
        padding: 1px;
        position: relative;
        width: calc((100% / 4) - (40px * 1));
        // width: 13.8vw;
        @include mq() {
          width: 100%;
        }
        &::after {
          border-bottom: 1.8vw solid transparent;
          border-left: 30px solid $color-darkgrey;
          // border-left: 3vw solid $color-darkgrey;
          border-top: 1.8vw solid transparent;
          content: '';
          display: block;
          height: 1.8vw;
          position: absolute;
          right: -30px;
          // right: -3vw;
          top: 50%;
          transform: translate(0, -50%);
          width: 30px;
          // width: 3vw;
          @include mq() {
            border-bottom: none;
            border-left: 3vw solid transparent;
            border-right: 3vw solid transparent;
            border-top: 3vw solid $color-darkgrey;
            bottom: -3vw;
            height: 1.8vw;
            left: 50%;
            right: auto;
            top: auto;
            transform: translate(-50%, 0);
          }
        }
        &:first-child {
          border: none;
          text-align: center;
          &::after {
            display: none;
          }
        }
        &:nth-child(2) {
          @include mq() {
              height: 20vw;
          }
          > div {
            font-size: 1.4rem;
            // font-size: 1.2vw;
            font-weight: 400;
            left: 50%;
            letter-spacing: .18em;
            position: absolute;
            text-align: center;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            @include mq() {
              font-size: 2rem;
            }
          }
        }
        &:nth-child(7) {
          &::after {
            display: none;
          }
        }
        &:last-child {
          border: none;
          text-align: center;
          &::after {
            display: none;
          }
        }
        .title {
          background: $color-orange;
          color: #fff;
          font-size: 1.6rem;
          font-weight: 400;
          letter-spacing: .18em;
          line-height: 1.2;
          padding: .25em;
          text-align: center;
          @include mq() {
            font-size: 2rem;
          }
        }
        > div:nth-child(2) {
          font-size: 1.4rem;
          // font-size: 1.2vw;
          font-weight: 400;
          height: calc(2em * 5);
          letter-spacing: .18em;
          line-height: 2;
          position: relative;
          text-align: center;
          @include mq() {
            font-size: 2rem;
          }
          p {
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
          }
        }
        img {
          width: 100%;
          @include mq() {
            width: 60%;
          }
        }
      }
    }
  } // &.flow-area
} // .knowledge-area
.about-contents-area {
  margin: 0 auto 5rem;
  width: 80%;
  @include mq() {
    width: 100%;
  }
  dl {
    border-top: 1px solid $color-border;
    display: flex;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.3;
    margin-bottom: 0;
    &:last-child {
      border-bottom: 1px solid $color-border;
    }
    dt {
      flex-basis: 25%;
      font-weight: 400;
      padding: 1em 0 1em 1em;
      &::before {
        color: $color-orange;
        content: '●';
        margin: 0 .75em 0 0;
      }
    }
    dd {
      flex-basis: 75%;
      margin-bottom: 0;
      padding: 1em 0;
      a {
        color: $color-text;
        text-decoration: none;
        transition: $duration;
        &:hover,&:active {
          color: $color-orange;
        }
        &.color-orange {
          color: $color-orange;
          &:hover, &:active {
            color: darken($color-orange, 20%);
          }
        }
      }
    }
    ul {
      list-style: none;
      padding: 1em 0;
    }
  }
} // .about-contents-area
.privacy-contents-area {
  ol {
    counter-reset: privacy;
    font-size: 1.6rem;
    list-style: none;
    padding: 0;
    li {
      background: $color-tan;
      font-weight: 400;
      line-height: 1.3;
      margin: 3em 0 1em;
      padding: 1em;
      &::before {
        content: counter(privacy)'.';
        counter-increment: privacy;
        margin: 0 .5em 0 0;
        padding: 0;
      }
      &:first-child {
        margin-top: 0;
      }
    }
    p {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.3;
      text-align: justify;
    }
    > div {
      margin: 10rem 0 5rem;
      p {
        font-size: 1.6rem;
        font-weight: 600;
      }
      .button {
        @extend %nice-button;
      }
    }
  } // ol
} // .privacy-contents-area
.sitemap-contents-area {
  margin: 0 0 10rem;
  dl {
    margin: 0 auto;
    padding: 0 0 0 150px;
    position: relative;
    width: 90%;
    @include mq() {
      width: 100%;
    }
    &::before {
      content: '';
      display: inline-block;
      height: 200px;
      left: 0;
      position: absolute;
      top: 0;
      width: 150px;
    }
    &:first-child {
      margin-bottom: 3rem;
      &::before {
        background: url(../images/top/illust_service.jpg) no-repeat top center;
        background-size: contain;
      }
    }
    &:nth-child(2) {
      &::before {
        background: url(../images/top/illust_knowledge.jpg) no-repeat top center;
        background-size: contain;
      }
    }
    dt {
      border-bottom: 1px solid $color-border;
      color: $color-orange;
      font-size: 2rem;
      font-weight: 400;
      padding: 2em .5em .5em;
    }
    dd {
      padding: 2em 1em 1em;
      a {
        background: $color-tan;
        border-radius: 1em;
        color: $color-text;
        display: inline-block;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1;
        margin: 0 1em 1em 0;
        padding: 1em;
        text-decoration: none;
        transition: $duration;
        white-space: nowrap;
        &:hover, &:active {
          background: $color-orange;
          color: #fff;
        }
      }
    }
  }
} // .sitemap-contents-area
.seminar-area {
  $image-size: 255px;
  background: $color-green;
  position: relative;
  > div {
    margin: 0;
    &.texts {
      align-items: center;
      flex-basis: calc(100% - #{$image-size});
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 1.5rem $image-size 1.5rem 1.5rem;
      > div {
        display: inline-block;
        margin: 0 1rem 0 0;
        &:first-child {
          background: #fff;
          border-radius: 1rem;
          color: $color-green;
          font-size: 1.8rem;
          font-weight: 400;
          letter-spacing: .18em;
          line-height: 1.3;
          padding: .25em .5em;
          text-align: center;
          strong {
            font-size: 120%;
            font-weight: 700;
            vertical-align: baseline;
          }
          span {
            font-weight: 700;
            vertical-align: baseline;
            &::before {
              content: '\A';
              white-space: pre;
              @include mq() {
                content: '';
              }
            }
          }
        }
        &:nth-child(2) {
          color: #fff;
          font-size: 4.4rem;
          font-weight: 700;
          letter-spacing: 0.16em;
          line-height: 1;
          padding: .125em 0;
          @include mq() {
            font-size: 4vw;
            padding: .5em 0;
          }
        }
        &:nth-child(3) {
          align-items: center;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          div {
            margin: 0 2rem 0 0;
          }
          div:nth-child(1) {
            font-size: 1.5rem;
            font-weight: 400;
            letter-spacing: .1em;
            margin: 0 .5em 0 0;
            &::after {
              content: '＞＞';
              letter-spacing: -.3em;
            }
          }
          div:nth-child(2) {
            font-family: $font-oswald;
            font-size: 3.2rem;
            font-weight: 600;
            &::before {
              background: url(../images/common/pict_tel.png) no-repeat center .125em;
              background-size: .875em;
              content: '';
              display: inline-block;
              height: 1em;
              margin: 0 .125em 0 0;
              width: 1em;
            }
            a {
              color: $color-text;
              text-decoration: none;
            }
          }
          div:nth-child(3) {
            font-size: 1.5rem;
            font-weight: 400;
            letter-spacing: .1em;
          }
        }
      }
    }
    &.request {
      bottom: 0;
      height: $image-size;
      position: absolute;
      right: 0;
      width: $image-size;
      @include mq() {
        width: 40vw;
      }
      a {
        display: inline-block;
        height: 100%;
        overflow: hidden;
        position: relative;
        width: 100%;
        img {
          bottom: -90px;
          display: block;
          position: absolute;
          right: 0;
          width: 100%;
        }
      }
    }
  } // > div
} // &.seminar-area
