@charset "UTF-8";
@import url(//fonts.googleapis.com/earlyaccess/notosansjapanese.css);
.title-area.knowledge .buttons, .top-contents-area.others-area .navi-area dl dd, .privacy-contents-area ol > div .button {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.4rem;
  font-weight: 400;
  justify-content: space-between;
  letter-spacing: .1em;
  line-height: 1.8;
  margin: 1em 0 0;
  text-align: justify;
}

.title-area.knowledge .buttons a, .top-contents-area.others-area .navi-area dl dd a, .privacy-contents-area ol > div .button a {
  border: 2px solid #dadada;
  color: #000000;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: .3em;
  line-height: 1;
  padding: 0.75em calc(22px + .75em) 0.75em 0.75em;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: 0.25s;
  width: 47.5%;
}

.title-area.knowledge .buttons a:hover, .top-contents-area.others-area .navi-area dl dd a:hover, .privacy-contents-area ol > div .button a:hover, .title-area.knowledge .buttons a:active, .top-contents-area.others-area .navi-area dl dd a:active, .privacy-contents-area ol > div .button a:active {
  border-color: #ea8228;
  background: #ea8228;
  color: #fff;
}

.title-area.knowledge .buttons a::after, .top-contents-area.others-area .navi-area dl dd a::after, .privacy-contents-area ol > div .button a::after {
  background: url(../images/common/pict_arrow_next.png) no-repeat center center;
  background-size: contain;
  content: '';
  display: inline-block;
  height: 22px;
  position: absolute;
  right: .75em;
  top: 50%;
  transform: translate(0, -50%);
  vertical-align: middle;
  width: 22px;
}

.title-area.knowledge .buttons a.local, .top-contents-area.others-area .navi-area dl dd a.local, .privacy-contents-area ol > div .button a.local {
  margin: 0 1em 1em 0;
  max-width: 45%;
  min-width: 20em;
}

.title-area.knowledge .buttons a.local::after, .top-contents-area.others-area .navi-area dl dd a.local::after, .privacy-contents-area ol > div .button a.local::after {
  background: url(../images/common/pict_arrow_local.png) no-repeat center center;
}

@media all and (max-width: 750px) {
  .title-area.knowledge .buttons a.local, .top-contents-area.others-area .navi-area dl dd a.local, .privacy-contents-area ol > div .button a.local {
    margin-right: 0;
    max-width: 100%;
    width: 100%;
  }
}

@font-face {
  font-family: 'NotoSerifCJKjp';
  font-style: normal;
  font-weight: 200;
  src: url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-ExtraLight.woff2) format("woff2"), url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-ExtraLight.woff) format("woff"), url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-ExtraLight.otf) format("opentype");
}

@font-face {
  font-family: 'NotoSerifCJKjp';
  font-style: normal;
  font-weight: 300;
  src: url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Light.woff2) format("woff2"), url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Light.woff) format("woff"), url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Light.otf) format("opentype");
}

@font-face {
  font-family: 'NotoSerifCJKjp';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Regular.woff2) format("woff2"), url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Regular.woff) format("woff"), url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Regular.otf) format("opentype");
}

@font-face {
  font-family: 'NotoSerifCJKjp';
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Medium.woff2) format("woff2"), url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Medium.woff) format("woff"), url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Medium.otf) format("opentype");
}

@font-face {
  font-family: 'NotoSerifCJKjp';
  font-style: normal;
  font-weight: 600;
  src: url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-SemiBold.woff2) format("woff2"), url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-SemiBold.woff) format("woff"), url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-SemiBold.otf) format("opentype");
}

@font-face {
  font-family: 'NotoSerifCJKjp';
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Bold.woff2) format("woff2"), url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Bold.woff) format("woff"), url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Bold.otf) format("opentype");
}

@font-face {
  font-family: 'NotoSerifCJKjp';
  font-style: normal;
  font-weight: 900;
  src: url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Black.woff2) format("woff2"), url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Black.woff) format("woff"), url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Black.otf) format("opentype");
}

@font-face {
  font-family: 'NotoSansCJKjp';
  font-style: normal;
  font-weight: 100;
  src: url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Thin.woff2) format("woff2"), url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Thin.woff) format("woff"), url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Thin.otf) format("opentype");
}

@font-face {
  font-family: 'NotoSansCJKjp';
  font-style: normal;
  font-weight: 300;
  src: url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Light.woff2) format("woff2"), url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Light.woff) format("woff"), url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Light.otf) format("opentype");
}

@font-face {
  font-family: 'NotoSansCJKjp';
  font-style: normal;
  font-weight: 350;
  src: url(../fonts/NotoSansCJKjp/NotoSansCJKjp-DemiLight.woff2) format("woff2"), url(../fonts/NotoSansCJKjp/NotoSansCJKjp-DemiLight.woff) format("woff"), url(../fonts/NotoSansCJKjp/NotoSansCJKjp-DemiLight.otf) format("opentype");
}

@font-face {
  font-family: 'NotoSansCJKjp';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Regular.woff2) format("woff2"), url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Regular.woff) format("woff"), url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Regular.otf) format("opentype");
}

@font-face {
  font-family: 'NotoSansCJKjp';
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Medium.woff2) format("woff2"), url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Medium.woff) format("woff"), url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Medium.otf) format("opentype");
}

@font-face {
  font-family: 'NotoSansCJKjp';
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Bold.woff2) format("woff2"), url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Bold.woff) format("woff"), url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Bold.otf) format("opentype");
}

@font-face {
  font-family: 'NotoSansCJKjp';
  font-style: normal;
  font-weight: 900;
  src: url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Black.woff2) format("woff2"), url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Black.woff) format("woff"), url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Black.otf) format("opentype");
}

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/Oswald/Oswald-Medium.ttf) format("truetype");
}

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
  src: url(../fonts/Oswald/Oswald-SemiBold.ttf) format("truetype");
}

html {
  font-size: 62.5%;
  /* font-size 1em = 10px on default browser settings */
  height: 100%;
}

body {
  font-family: "NotoSansCJKjp", sans-serif;
  min-height: 100%;
  position: relative;
  /* go-to-top 対応 */
}

header {
  display: block;
  position: relative;
}

header .sitelogo-area {
  font-size: 2.4rem;
  font-weight: 500;
  letter-spacing: .2em;
  line-height: 1.5;
}

header .sitelogo-area a {
  color: #000000;
  display: inline-block;
  padding: 1em 0 .5em;
  text-decoration: none;
}

header .sitelogo-area span {
  display: block;
  font-size: 1.6rem;
  font-size: 65%;
  font-weight: 400;
}

footer {
  border-top: 1px solid #c4c4c4;
  display: block;
  margin: 5rem 0 0;
  position: relative;
}

footer .address-area {
  font-size: 1.5rem;
  font-weight: 350;
  padding: 2rem 0;
}

footer .address-area address {
  display: block;
  letter-spacing: .2em;
  line-height: 1;
  margin: 0;
  padding: 0;
}

footer .address-area address strong {
  display: block;
  font-weight: 500;
  padding: 0 0 1em 0;
}

footer .copyright-area {
  align-items: center;
  background: #c4c4c4;
  color: #fff;
  display: flex;
  font-size: 1.4rem;
  font-weight: 350;
  height: 50px;
  letter-spacing: .1em;
}

main {
  display: block;
}

h1 {
  font-family: "NotoSerifCJKjp", serif;
  font-size: 5rem;
  font-weight: 400;
  letter-spacing: .18em;
}

h1.top {
  font-size: 4rem;
  letter-spacing: .08em;
  padding: 1em 0 0;
  text-align: center;
}

h1.top::before {
  background-image: none;
  display: none;
}

h1.top strong {
  font-size: 120%;
}

h1 strong {
  color: #ea8228;
  font-weight: 400;
}

h1 span {
  font-size: 80%;
}

h2 {
  border-bottom: 3px solid #000;
  border-top: 3px transparent;
  display: inline-block;
  font-size: 2.4rem;
  font-weight: 500;
  letter-spacing: .18em;
  margin: 0;
  padding: .5em 0;
}

.contents-width {
  margin: 0 auto;
  width: 980px;
}

@media all and (max-width: 750px) {
  .contents-width {
    width: 90vw;
  }
}

.h2-area {
  background: #feefd8;
  text-align: center;
}

.go-to-top {
  background: #000;
  bottom: 5vw;
  cursor: pointer;
  height: 120px;
  opacity: 0;
  position: fixed;
  right: 0;
  text-align: center;
  transition: 0.5s;
  width: 40px;
  z-index: 1;
}

@media all and (max-width: 750px) {
  .go-to-top {
    height: 90px;
    width: 90px;
  }
}

.go-to-top a {
  display: block;
  height: 100%;
  position: relative;
  width: 100%;
}

.go-to-top::before {
  background: url(../images/common/pict_go_to_top.png);
  background-size: contain;
  content: '';
  height: 24px;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translate(-50%, 24px);
  width: 24px;
}

@media all and (max-width: 750px) {
  .go-to-top::before {
    height: 50px;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
  }
}

.go-to-top.active {
  opacity: 1;
}

.contact-area {
  background: #ea8228;
  color: #fff;
  padding: 0 1.5rem 1.5rem;
  position: absolute;
  right: calc( (100vw - 980px) / 2);
  text-align: center;
  width: 250px;
}

@media all and (max-width: 750px) {
  .contact-area {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    right: auto;
    width: 100%;
  }
}

.contact-area.in-header {
  border-radius: 0 0 1rem 1rem;
  top: 0;
}

@media all and (max-width: 750px) {
  .contact-area.in-header {
    border-radius: 0;
    padding: 1em 0;
    top: auto;
  }
}

.contact-area.in-footer {
  border-radius: 1rem 1rem 0 0;
  bottom: 50px;
}

@media all and (max-width: 750px) {
  .contact-area.in-footer {
    border-radius: 0;
    bottom: auto;
    padding: 1em 0;
  }
}

.contact-area p {
  letter-spacing: 1;
  line-height: 1;
  margin: 0;
}

.contact-area p:first-child {
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: .1em;
  padding: 1.25em 0;
}

@media all and (max-width: 750px) {
  .contact-area p:first-child {
    font-size: 2.2rem;
    padding: 0;
  }
}

.contact-area p:nth-child(2) {
  color: #000000;
  font-family: "Oswald", "NotoSansCJKjp", sans-serif;
  font-size: 3rem;
  font-weight: 600;
  padding: 0 0 .5em;
}

@media all and (max-width: 750px) {
  .contact-area p:nth-child(2) {
    font-size: 4rem;
    margin: 0 .5em;
    padding: 0;
  }
}

.contact-area p:nth-child(2)::before {
  background: url(../images/common/pict_tel.png) no-repeat center center;
  background-size: contain;
  content: '';
  display: inline-block;
  height: .8em;
  margin: 0 .25em 0 0;
  width: .8em;
}

.contact-area p:nth-child(2) a {
  color: #000000;
  text-decoration: none;
}

.contact-area p:nth-child(3) {
  font-size: 1.4rem;
  font-weight: 350;
  letter-spacing: 0.14em;
  padding: 0 0 1.25em;
}

@media all and (max-width: 750px) {
  .contact-area p:nth-child(3) {
    font-size: 2rem;
    padding: 0 1em 0 0;
  }
}

.contact-area p:last-child a {
  background: #fff;
  border-radius: 2rem;
  color: #ea8228;
  display: block;
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: .05em;
  padding: .5em 0;
  text-align: center;
  text-decoration: none;
  transition: 0.5s;
}

@media all and (max-width: 750px) {
  .contact-area p:last-child a {
    display: inline-block;
    font-size: 2rem;
    margin: .5em 0;
    padding: .5em 1em;
    vertical-align: middle;
  }
}

.contact-area p:last-child a::after {
  color: #000000;
  content: '▶︎';
  margin: 0 0 0 .25em;
  transition: 0.5s;
}

.contact-area p:last-child a:hover {
  background: #000;
  color: #fff;
}

.contact-area p:last-child a:hover::after {
  color: #fff;
}

.global-menu-area {
  background: #e9e9e9;
}

.global-menu-area #toggle-menu {
  display: none;
}

.global-menu-area label {
  display: none;
}

.global-menu-area nav {
  display: flex;
  padding: 0 calc(250px + 5vw) 0 0;
}

@media all and (max-width: 750px) {
  .global-menu-area nav {
    padding: 0;
  }
}

.global-menu-area.in-header {
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: .1em;
}

@media all and (max-width: 750px) {
  .global-menu-area.in-header {
    font-size: 2rem;
  }
}

.global-menu-area.in-header nav {
  justify-content: space-between;
}

.global-menu-area.in-header nav a {
  border-bottom: 3px solid transparent;
  border-top: 3px solid transparent;
  color: #000000;
  display: block;
  padding: 1em 0;
  text-decoration: none;
  transition: 0.5s;
}

.global-menu-area.in-header nav a.active {
  border-bottom: 3px solid #ea8228;
}

.global-menu-area.in-header nav a:hover, .global-menu-area.in-header nav a:active {
  border-bottom: 3px solid #ea8228;
}

.global-menu-area.in-footer {
  font-size: 1.4rem;
  font-weight: 350;
  letter-spacing: .1em;
  padding: 1em 0;
}

@media all and (max-width: 750px) {
  .global-menu-area.in-footer {
    padding: 0;
  }
}

.global-menu-area.in-footer nav {
  flex-wrap: wrap;
}

@media all and (max-width: 750px) {
  .global-menu-area.in-footer nav {
    flex-direction: column;
    padding: 0;
  }
}

.global-menu-area.in-footer nav a {
  color: #000000;
  display: block;
  line-height: 1;
  padding: .5em 3em .5em 0;
  text-decoration: none;
  transition: 0.25s;
}

@media all and (max-width: 750px) {
  .global-menu-area.in-footer nav a {
    border-bottom: 1px dotted #a7a7a7;
    font-size: 120%;
    padding: 1em 0;
    position: relative;
    width: 100%;
  }
  .global-menu-area.in-footer nav a:last-child {
    border-bottom: none;
  }
}

.global-menu-area.in-footer nav a:hover, .global-menu-area.in-footer nav a:active {
  color: #ea8228;
}

.global-menu-area.in-footer nav a::after {
  background: url(../images/common/pict_arrow_next.png) no-repeat center center;
  background-size: contain;
  content: '';
  display: inline-block;
  height: .75em;
  margin: 0 0 0 .125em;
  width: .75em;
}

@media all and (max-width: 750px) {
  .global-menu-area.in-footer nav a::after {
    position: absolute;
    right: 1em;
    top: 50%;
    transform: translate(0, -50%);
  }
}

.title-area {
  position: relative;
}

.title-area.top {
  padding-left: 0;
  text-align: center;
}

.title-area.top::before {
  background: #8c766c;
  bottom: 21px;
  content: '';
  display: block;
  height: 3px;
  left: 0vw;
  position: absolute;
  width: 100%;
  z-index: -1;
}

.title-area.knowledge {
  padding: 5rem 0 5rem 300px;
}

@media all and (max-width: 750px) {
  .title-area.knowledge {
    padding: 5rem 0 5rem 250px;
  }
}

.title-area.knowledge::before {
  background: url(../images/knowledge/illust_main.jpg) no-repeat top center;
  background-size: contain;
  content: '';
  display: inline-block;
  height: 300px;
  left: 0;
  position: absolute;
  top: 4rem;
  width: 300px;
}

@media all and (max-width: 750px) {
  .title-area.knowledge::before {
    height: 250px;
    width: 250px;
  }
}

@media all and (max-width: 750px) {
  .title-area.knowledge .buttons {
    justify-content: flex-start;
  }
}

.title-area.other {
  padding: 7rem 0 5rem;
  text-align: center;
}

@media all and (max-width: 750px) {
  .title-area {
    padding: 0 5vw;
  }
}

.title-area h1 + p {
  font-size: 1.6rem;
  font-weight: 350;
  letter-spacing: .1em;
  line-height: 2;
  margin: 1em 0;
}

@media all and (max-width: 750px) {
  .title-area h1 + p {
    padding: 0 5vw;
  }
}

.title-area figure {
  display: block;
}

.title-area figure img {
  width: 100%;
}

.top-contents-area.about-area {
  background: #feefd8;
  border-radius: 3rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 5rem;
  margin-top: 5rem;
  padding: 0 4rem 4rem;
  position: relative;
}

@media all and (max-width: 750px) {
  .top-contents-area.about-area {
    padding: 0 5vw 5vw;
  }
}

.top-contents-area.about-area .title {
  background: #ea8228;
  border-radius: 1em;
  color: #fff;
  flex-basis: 95%;
  font-size: 2.2rem;
  letter-spacing: .1em;
  line-height: 1;
  margin: -1em auto 2em;
  padding: .5em 2em;
  text-align: center;
}

.top-contents-area.about-area dl {
  display: block;
  width: calc((100% - (4rem * 2)) / 2);
}

@media all and (max-width: 750px) {
  .top-contents-area.about-area dl {
    width: calc((100% - (5vw * 1)) / 2);
  }
}

.top-contents-area.about-area dl dt {
  color: #ea8228;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: .15em;
  line-height: 1.2;
  margin: 0 0 1em;
  padding: 0 0 0 calc(37px + .5em);
  text-indent: calc(-1 * (37px + .5em));
}

.top-contents-area.about-area dl dt::before {
  content: url(../images/top/pict_attention.png);
  margin: 0 .5em 0 0;
  vertical-align: middle;
}

.top-contents-area.about-area dl dd {
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: .1em;
  line-height: 2;
  text-align: justify;
}

.top-contents-area.about-area figure {
  text-align: center;
}

.top-contents-area.about-area figure img {
  vertical-align: bottom;
}

.top-contents-area.about-area .conclution {
  color: #ea8228;
  font-size: 2.8rem;
  font-weight: 400;
  letter-spacing: .1em;
  text-align: center;
  width: 100%;
}

.top-contents-area.about-area .conclution::before {
  border-left: 150px solid transparent;
  border-right: 150px solid transparent;
  border-top: 50px solid #f8d3b3;
  content: '';
  display: block;
  margin: 0 auto .5em;
  width: 300px;
}

.top-contents-area.others-area {
  display: flex;
}

@media all and (max-width: 750px) {
  .top-contents-area.others-area {
    flex-direction: column;
  }
}

.top-contents-area.others-area .navi-area {
  margin: 0 5% 0 0;
  width: 70%;
}

@media all and (max-width: 750px) {
  .top-contents-area.others-area .navi-area {
    width: 100%;
  }
}

.top-contents-area.others-area .navi-area dl {
  margin: 0 0 6rem;
  padding: 0 0 0 130px;
  position: relative;
}

.top-contents-area.others-area .navi-area dl::before {
  display: inline-block;
  left: 0;
  position: absolute;
  top: 0;
  width: 130px;
}

.top-contents-area.others-area .navi-area dl:first-child::before {
  content: url(../images/top/illust_service.jpg);
}

.top-contents-area.others-area .navi-area dl:nth-child(2)::before {
  content: url(../images/top/illust_knowledge.jpg);
}

.top-contents-area.others-area .navi-area dl:nth-child(3)::before {
  content: url(../images/top/illust_q_and_a.jpg);
}

.top-contents-area.others-area .navi-area dl:nth-child(3) dd a {
  width: 100%;
}

.top-contents-area.others-area .navi-area dl dt {
  border-bottom: 1px solid #a7a7a7;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: .1em;
  line-height: 1;
  padding: 0 0 .75em;
}

.top-contents-area.others-area .news-area {
  padding: 0 0 5rem;
  width: 25%;
}

@media all and (max-width: 750px) {
  .top-contents-area.others-area .news-area {
    width: 100%;
  }
}

.top-contents-area.others-area .news-area .title {
  border-bottom: 1px solid #a7a7a7;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: .1em;
  line-height: 1;
  padding: 0 0 .75em 3em;
  position: relative;
}

.top-contents-area.others-area .news-area .title::before {
  content: url(../images/top/pict_news.png);
  display: inline-block;
  left: 0;
  margin: 0 .5em 0 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -65%);
}

.top-contents-area.others-area .news-area .news {
  list-style: none;
  padding: 0;
}

.top-contents-area.others-area .news-area .news li {
  border-bottom: 1px dotted #a7a7a7;
  font-size: 1.4rem;
  font-weight: 350;
  letter-spacing: .1em;
  line-height: 1.5;
  padding: 1em 2em 1em 0;
  position: relative;
  text-align: justify;
  width: 100%;
}

.top-contents-area.others-area .news-area .news li a {
  color: #000000;
  display: block;
  text-decoration: none;
  transition: 0.25s;
}

.top-contents-area.others-area .news-area .news li a::after {
  background: url(../images/common/pict_arrow_next.png) no-repeat center center;
  background-size: contain;
  content: '';
  height: 16px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 16px;
}

.top-contents-area.others-area .news-area .news li a:hover, .top-contents-area.others-area .news-area .news li a:active {
  color: #77cc44;
}

.top-contents-area.others-area .news-area .news li a span {
  color: #77cc44;
  display: block;
  font-size: 1.2rem;
  line-height: 1;
  margin: 0 0 .5em;
}

.top-contents-area.others-area .banners-area {
  padding: 2rem 0 0;
  text-align: center;
}

.top-contents-area.others-area .banners-area img {
  width: 100%;
}

@media all and (max-width: 750px) {
  .top-contents-area.others-area .banners-area img {
    width: 60vw;
  }
}

@media all and (max-width: 750px) {
  .knowledge-contents-area.rules-area {
    flex-direction: column;
  }
}

.knowledge-contents-area.rules-area ol {
  counter-reset: rules;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  margin: 4rem 0;
  padding: 0;
}

.knowledge-contents-area.rules-area ol li {
  position: relative;
  width: calc((100% - 5rem) / 2);
}

@media all and (max-width: 750px) {
  .knowledge-contents-area.rules-area ol li {
    width: 100%;
  }
}

.knowledge-contents-area.rules-area ol li::before {
  background: #ea8228;
  border-radius: calc(50px / 2);
  color: #fff;
  counter-increment: rules;
  content: counter(rules);
  display: inline-block;
  font-family: "Oswald", "NotoSansCJKjp", sans-serif;
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 1;
  left: 0;
  margin: 0;
  padding: calc(57px - (2.2rem * 2)) 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 50px;
}

.knowledge-contents-area.rules-area ol li::after {
  content: url(../images/knowledge/illust_rules.png);
  position: absolute;
  left: 35px;
  top: 10px;
}

.knowledge-contents-area.rules-area ol li .title {
  border-bottom: 1px solid #a7a7a7;
  font-size: 2.4rem;
  font-weight: 400;
  letter-spacing: .18em;
  line-height: 1.2;
  margin: 0 0 1em;
  padding: 1.5em 0 .75em;
  text-indent: 100px;
}

.knowledge-contents-area.rules-area ol li div:nth-child(2) {
  display: flex;
  justify-content: space-between;
}

.knowledge-contents-area.rules-area ol li div:nth-child(2) p {
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: .18em;
  line-height: 2;
  text-align: justify;
  width: 70%;
}

.knowledge-contents-area.rules-area ol li div:nth-child(2) figure {
  width: 25%;
}

.knowledge-contents-area.rules-area ol li div:nth-child(2) figure img {
  width: 100%;
}

.knowledge-contents-area.flow-area ol {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  padding: 5rem 0;
}

.knowledge-contents-area.flow-area ol li {
  border: 1px solid #a7a7a7;
  margin: 0 40px 40px 0;
  padding: 1px;
  position: relative;
  width: calc((100% / 4) - (40px * 1));
}

@media all and (max-width: 750px) {
  .knowledge-contents-area.flow-area ol li {
    width: 100%;
  }
}

.knowledge-contents-area.flow-area ol li::after {
  border-bottom: 1.8vw solid transparent;
  border-left: 30px solid #c4c4c4;
  border-top: 1.8vw solid transparent;
  content: '';
  display: block;
  height: 1.8vw;
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translate(0, -50%);
  width: 30px;
}

@media all and (max-width: 750px) {
  .knowledge-contents-area.flow-area ol li::after {
    border-bottom: none;
    border-left: 3vw solid transparent;
    border-right: 3vw solid transparent;
    border-top: 3vw solid #c4c4c4;
    bottom: -3vw;
    height: 1.8vw;
    left: 50%;
    right: auto;
    top: auto;
    transform: translate(-50%, 0);
  }
}

.knowledge-contents-area.flow-area ol li:first-child {
  border: none;
  text-align: center;
}

.knowledge-contents-area.flow-area ol li:first-child::after {
  display: none;
}

@media all and (max-width: 750px) {
  .knowledge-contents-area.flow-area ol li:nth-child(2) {
    height: 20vw;
  }
}

.knowledge-contents-area.flow-area ol li:nth-child(2) > div {
  font-size: 1.4rem;
  font-weight: 400;
  left: 50%;
  letter-spacing: .18em;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

@media all and (max-width: 750px) {
  .knowledge-contents-area.flow-area ol li:nth-child(2) > div {
    font-size: 2rem;
  }
}

.knowledge-contents-area.flow-area ol li:nth-child(7)::after {
  display: none;
}

.knowledge-contents-area.flow-area ol li:last-child {
  border: none;
  text-align: center;
}

.knowledge-contents-area.flow-area ol li:last-child::after {
  display: none;
}

.knowledge-contents-area.flow-area ol li .title {
  background: #ea8228;
  color: #fff;
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: .18em;
  line-height: 1.2;
  padding: .25em;
  text-align: center;
}

@media all and (max-width: 750px) {
  .knowledge-contents-area.flow-area ol li .title {
    font-size: 2rem;
  }
}

.knowledge-contents-area.flow-area ol li > div:nth-child(2) {
  font-size: 1.4rem;
  font-weight: 400;
  height: calc(2em * 5);
  letter-spacing: .18em;
  line-height: 2;
  position: relative;
  text-align: center;
}

@media all and (max-width: 750px) {
  .knowledge-contents-area.flow-area ol li > div:nth-child(2) {
    font-size: 2rem;
  }
}

.knowledge-contents-area.flow-area ol li > div:nth-child(2) p {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.knowledge-contents-area.flow-area ol li img {
  width: 100%;
}

@media all and (max-width: 750px) {
  .knowledge-contents-area.flow-area ol li img {
    width: 60%;
  }
}

.about-contents-area {
  margin: 0 auto 5rem;
  width: 80%;
}

@media all and (max-width: 750px) {
  .about-contents-area {
    width: 100%;
  }
}

.about-contents-area dl {
  border-top: 1px solid #a7a7a7;
  display: flex;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.3;
  margin-bottom: 0;
}

.about-contents-area dl:last-child {
  border-bottom: 1px solid #a7a7a7;
}

.about-contents-area dl dt {
  flex-basis: 25%;
  font-weight: 400;
  padding: 1em 0 1em 1em;
}

.about-contents-area dl dt::before {
  color: #ea8228;
  content: '●';
  margin: 0 .75em 0 0;
}

.about-contents-area dl dd {
  flex-basis: 75%;
  margin-bottom: 0;
  padding: 1em 0;
}

.about-contents-area dl dd a {
  color: #000000;
  text-decoration: none;
  transition: 0.5s;
}

.about-contents-area dl dd a:hover, .about-contents-area dl dd a:active {
  color: #ea8228;
}

.about-contents-area dl dd a.color-orange {
  color: #ea8228;
}

.about-contents-area dl dd a.color-orange:hover, .about-contents-area dl dd a.color-orange:active {
  color: #9d510f;
}

.about-contents-area dl ul {
  list-style: none;
  padding: 1em 0;
}

.privacy-contents-area ol {
  counter-reset: privacy;
  font-size: 1.6rem;
  list-style: none;
  padding: 0;
}

.privacy-contents-area ol li {
  background: #feefd8;
  font-weight: 400;
  line-height: 1.3;
  margin: 3em 0 1em;
  padding: 1em;
}

.privacy-contents-area ol li::before {
  content: counter(privacy) ".";
  counter-increment: privacy;
  margin: 0 .5em 0 0;
  padding: 0;
}

.privacy-contents-area ol li:first-child {
  margin-top: 0;
}

.privacy-contents-area ol p {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.3;
  text-align: justify;
}

.privacy-contents-area ol > div {
  margin: 10rem 0 5rem;
}

.privacy-contents-area ol > div p {
  font-size: 1.6rem;
  font-weight: 600;
}

.sitemap-contents-area {
  margin: 0 0 10rem;
}

.sitemap-contents-area dl {
  margin: 0 auto;
  padding: 0 0 0 150px;
  position: relative;
  width: 90%;
}

@media all and (max-width: 750px) {
  .sitemap-contents-area dl {
    width: 100%;
  }
}

.sitemap-contents-area dl::before {
  content: '';
  display: inline-block;
  height: 200px;
  left: 0;
  position: absolute;
  top: 0;
  width: 150px;
}

.sitemap-contents-area dl:first-child {
  margin-bottom: 3rem;
}

.sitemap-contents-area dl:first-child::before {
  background: url(../images/top/illust_service.jpg) no-repeat top center;
  background-size: contain;
}

.sitemap-contents-area dl:nth-child(2)::before {
  background: url(../images/top/illust_knowledge.jpg) no-repeat top center;
  background-size: contain;
}

.sitemap-contents-area dl dt {
  border-bottom: 1px solid #a7a7a7;
  color: #ea8228;
  font-size: 2rem;
  font-weight: 400;
  padding: 2em .5em .5em;
}

.sitemap-contents-area dl dd {
  padding: 2em 1em 1em;
}

.sitemap-contents-area dl dd a {
  background: #feefd8;
  border-radius: 1em;
  color: #000000;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1;
  margin: 0 1em 1em 0;
  padding: 1em;
  text-decoration: none;
  transition: 0.5s;
  white-space: nowrap;
}

.sitemap-contents-area dl dd a:hover, .sitemap-contents-area dl dd a:active {
  background: #ea8228;
  color: #fff;
}

.seminar-area {
  background: #77cc44;
  position: relative;
}

.seminar-area > div {
  margin: 0;
}

.seminar-area > div.texts {
  align-items: center;
  flex-basis: calc(100% - 255px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1.5rem 255px 1.5rem 1.5rem;
}

.seminar-area > div.texts > div {
  display: inline-block;
  margin: 0 1rem 0 0;
}

.seminar-area > div.texts > div:first-child {
  background: #fff;
  border-radius: 1rem;
  color: #77cc44;
  font-size: 1.8rem;
  font-weight: 400;
  letter-spacing: .18em;
  line-height: 1.3;
  padding: .25em .5em;
  text-align: center;
}

.seminar-area > div.texts > div:first-child strong {
  font-size: 120%;
  font-weight: 700;
  vertical-align: baseline;
}

.seminar-area > div.texts > div:first-child span {
  font-weight: 700;
  vertical-align: baseline;
}

.seminar-area > div.texts > div:first-child span::before {
  content: '\A';
  white-space: pre;
}

@media all and (max-width: 750px) {
  .seminar-area > div.texts > div:first-child span::before {
    content: '';
  }
}

.seminar-area > div.texts > div:nth-child(2) {
  color: #fff;
  font-size: 4.4rem;
  font-weight: 700;
  letter-spacing: 0.16em;
  line-height: 1;
  padding: .125em 0;
}

@media all and (max-width: 750px) {
  .seminar-area > div.texts > div:nth-child(2) {
    font-size: 4vw;
    padding: .5em 0;
  }
}

.seminar-area > div.texts > div:nth-child(3) {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.seminar-area > div.texts > div:nth-child(3) div {
  margin: 0 2rem 0 0;
}

.seminar-area > div.texts > div:nth-child(3) div:nth-child(1) {
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: .1em;
  margin: 0 .5em 0 0;
}

.seminar-area > div.texts > div:nth-child(3) div:nth-child(1)::after {
  content: '＞＞';
  letter-spacing: -.3em;
}

.seminar-area > div.texts > div:nth-child(3) div:nth-child(2) {
  font-family: "Oswald", "NotoSansCJKjp", sans-serif;
  font-size: 3.2rem;
  font-weight: 600;
}

.seminar-area > div.texts > div:nth-child(3) div:nth-child(2)::before {
  background: url(../images/common/pict_tel.png) no-repeat center 0.125em;
  background-size: .875em;
  content: '';
  display: inline-block;
  height: 1em;
  margin: 0 .125em 0 0;
  width: 1em;
}

.seminar-area > div.texts > div:nth-child(3) div:nth-child(2) a {
  color: #000000;
  text-decoration: none;
}

.seminar-area > div.texts > div:nth-child(3) div:nth-child(3) {
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: .1em;
}

.seminar-area > div.request {
  bottom: 0;
  height: 255px;
  position: absolute;
  right: 0;
  width: 255px;
}

@media all and (max-width: 750px) {
  .seminar-area > div.request {
    width: 40vw;
  }
}

.seminar-area > div.request a {
  display: inline-block;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.seminar-area > div.request a img {
  bottom: -90px;
  display: block;
  position: absolute;
  right: 0;
  width: 100%;
}
